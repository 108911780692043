.plateImage {
  border-radius: 4px;
  max-height: 100px;
  width: 200px;
  min-width: 200px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin: 10px;
}

#reportTableDiv {
  overflow-x: auto;
}
