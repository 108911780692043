.eyeson-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.avatar-initials {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.ui-daterangepicker,
.daterangepicker,
.dropdown-menu {
  z-index: 2000 !important;
}

.dt-button-collection {
  z-index: 2000 !important;
}

.eyeson-btn {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  height: 100px;
  width: 150px;
  white-space: normal;
  word-wrap: break-word;
  margin: 2px;
}

@media (max-width: 500px) {
  .eyeson-btn {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    height: 70px;
    width: 120px;
    white-space: normal;
    word-wrap: break-word;
    margin: 2px;
  }
}

#toolsbuttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
}

#messageInput {
  width: 100%;
  padding-right: 90px;
}

.maxWidth {
  width: 100% !important;
}

.width90 {
  width: 90% !important;
}

.marginVertical10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.justifyCenter {
  justify-content: center;
}

.btn-red {
  color: #fff;
  background-color: #a01010;
  border-color: #a01010;
  min-height: 100px;
  max-height: 100px;
}

.appVersionDiv {
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.appVersionText {
  color: #495057;
  font-weight: 600;
}

.offlineDiv {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.offlineImage {
  filter: invert(30%) sepia(17%) saturate(303%) hue-rotate(169deg)
    brightness(93%) contrast(90%);
}

.offlineText {
  font-weight: 700;
  color: #495057;
  font-size: 18px;
}

.offlineTextSmaller {
  font-weight: 700;
  color: #495057;
  font-size: 14px;
}

.logoutModalContainer {
  position: fixed;
  width: 300px;
  height: 200px;
  padding: 10px;
  border-radius: 14px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  flex-direction: column;
  display: flex;
  margin-left: -150px;
  justify-content: space-around;
}

.tagModalContainer {
  height: 330px;
  width: 400px;
  position: fixed;
  padding: 30px;
  border-radius: 14px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  flex-direction: column;
  display: flex;
  margin-left: -150px;
  justify-content: space-around;
}

.modalTitleContainer {
  height: 40%;
  width: 100%;
  display: flex;
  /* background-color: red; */
  align-items: center;
  justify-content: center;
}
.modalSubtitleContainer {
  height: 30%;
  width: 100%;
  display: flex;
  background-color: blue;
  align-items: center;
  justify-content: center;
}
.modalButtonsContainer {
  height: 40%;
  width: 100%;
  display: flex;
  /* background-color: green; */
  align-items: center;
  justify-content: space-around;
}

.logoutModalButton {
  width: 100px;
  height: 50px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.backgroundRed {
  background-color: #a01010;
}

.backgroundRed:hover {
  background-color: #c71515;
}

.backgroundBlue {
  background-color: #10314c;
}

.backgroundBlue:hover {
  background-color: #1a70d1;
}

.logoutModalButtonText {
  font-size: 16px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.logoutModalTitle {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.logoutModalSubtitle {
}

.fadeBackground {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: initial;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.dropzoneContainer {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  background: #0008;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .dropzone {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
} */

.dropzoneContent {
  background-color: white;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  padding: 20px;
  /* display: flex; */
  /* align-items: center;
  flex-direction: column; */
}

.flexAlignCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px;
}

.displayNone {
  display: none !important;
}

.displayInitial {
  display: initial !important;
}

.displayFlex {
  display: flex !important;
}

.redButton {
  background-color: #a01010;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.redButton:hover {
  background-color: #c71515;
}

.blueButton {
  background-color: #10314c;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonText {
  color: #fff;
  text-align: center;
  margin: 0px !important;
}

.blueButton:hover {
  background-color: #1e11fe;
}

.uploadModalButtonsDiv {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

#sendUploadModal {
  width: 150px;
  height: 50px;
}

#closeUploadModal {
  width: 100px;
  height: 30px;
}

.loadingGif {
  width: 20px;
  height: 20px;
}

.documentInChat {
  max-width: 230px;
  max-height: 180px;
  border-radius: 8px;
  cursor: pointer;
}

#showModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invisibleHeader {
  display: none !important;
}

.displayFlex {
  display: flex;
}

@media (max-width: 1000px) {
  .invisibleHeader {
    display: flex !important;
  }
}

.clickable {
  cursor: pointer;
}

.fullSize {
  display: flex;

  width: auto;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.eyeson-actionButton {
}

.uploadChatButton {
  width: 35px;
  height: 35px;
}

.uploadChatButtonContainer {
  width: 40px !important;
}
