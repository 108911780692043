.ReactTags__tags {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: flex;
  flex: 1;
}
.ReactTags__tagInput input.ReactTags__tagInputField {
  margin: 0;
  font-size: 12px;
  height: 30px;
  flex: 1;
  border: none;
  outline: none;
}

.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  border: none;
}

.ReactTags__selected {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #50647b;
  color: white;
  font-size: 12px;
  display: flex;
  padding: 5px;
  height: 30px;
  margin: 0px 5px 0px 0px;
  border-radius: 4px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  /* display: flex; */
  top: 100%;
  overflow-y: auto;
  border-radius: 12px;
  max-height: 200px;
  box-shadow: 0.5em 0.01em 0.5em rgba(0, 0, 0, 0.2);
}
.ReactTags__suggestions ul {
  list-style-type: none;
  background: white;
  padding: 0;
  width: 200px;
}
.ReactTags__suggestions li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.ReactTags__remove :focus {
  border: none;
  outline: none;
  background: none;
}

.DatePicker {
  width: 100%;
  border-radius: 4px;
  height: 42px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 12px;
  border: 1px solid #aaaaaa;
  outline: none;
}

::-webkit-scrollbar {
  /* width: 3px;
  height: 30px;
  color: #0009;
  background-color: #0009; */
}
